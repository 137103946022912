import { ValidationObserver, ValidationProvider } from 'vee-validate'
import get from 'lodash.get'
import globalHelper from '@/mixins/global'

export default {
  name: 'contact',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],
  data () {
    return {
      internal_email: null,
      subject: null,
      body: null,
      success: false,
      contacting: false
    }
  },
  created () {
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.observer && this.$refs.observer.ctx && this.$refs.observer.ctx.invalid && this.$refs.observer.validate()
          break
      }
    })
  },
  beforeDestroy () {
  },

  computed: {
    email: {
      get () {
        return this.internal_email !== null ? this.internal_email
          : get(this.$store.state, 'user.email', null)
      },
      set (value) {
        this.internal_email = value
      }
    }
  },
  methods: {
    async sendMessage (event) {
      event.preventDefault()
      event.stopPropagation()

      if (this.contacting) {
        return
      }

      this.success = false

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      const body = {
        user_id: get(this.$store.state, 'user.id', null),
        email: this.email,
        subject: this.subject,
        body: this.body
      }

      this.contacting = true
      this.$http.post(
        'v1/messages',
        body,
        null
      ).then(
        (response) => {
          this.contacting = false
          if (response.body) {
            this.success = true

            // reset form
            this.subject = null
            this.body = null
            // You should call it on the next frame
            requestAnimationFrame(() => {
              this.$refs.observer.reset()
            })

            window.scrollTo(0, 0)
            return
          }

          this.success = false
          this.printErrors(response, this.$refs.observer)
        },
        (response) => {
          this.contacting = false
          this.success = false
          this.handleErrors(response)
        }
      )
    }
  }
}
